import React from "react";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
const Footer = () => {
  const selectedStore = localStorage.getItem("selectedStore");
  const parsedStore = selectedStore ? JSON.parse(selectedStore) : null;
  const { phone_no, address, email, facebookLink, whatsApp, instagramLink, linkedinLink,youtubeLink } = parsedStore || {};

  return (
    <footer className="bg-background text-foreground p-6 bg-slate-300">
      <div className="text-start mb-4">
        <h1 className="text-2xl font-bold">Food4You.</h1>
        <p className="text-muted-foreground">{address}</p>
      </div>
      <div className="text-star mb-4">
        <h2 className="text-lg font-semibold">Contact</h2>
        <p className="text-muted-foreground">{phone_no}</p>
        <p className="text-muted-foreground">{email}</p>
      </div>
      <div className="mb-4">
        <input
          type="email"
          placeholder="Email Address"
          className="border border-border rounded-lg p-2 w-full max-w-xs mx-auto"
        />
      </div>
      <p className="text-muted-foreground mb-4">
        Join our subscribers and get the best recipes delivered each week!
      </p>

      {/* ? Social Media Links */}
      <div className="flex justify-start space-x-4 mt-4">
        {instagramLink && (
          <a href={instagramLink} target="_blank" rel="noreferrer" aria-label="Instagram">
            <InstagramIcon className="text-pink-600" />
          </a>
        )}
        {facebookLink && (
          <a href={facebookLink} target="_blank" rel="noreferrer" aria-label="Facebook">
            <FacebookIcon className="text-blue-600" />
          </a>
        )}
        {youtubeLink && (
          <a href={youtubeLink} target="_blank" rel="noreferrer" aria-label="WhatsApp">
            <YouTubeIcon className="text-red-600" />
          </a>
        )}
        {linkedinLink && (
          <a href={linkedinLink} target="_blank" rel="noreferrer" aria-label="LinkedIn">
            <LinkedInIcon className="text-blue-700" />
          </a>
        )}
      </div>

      <div className="text-center mt-4 text-muted-foreground">
        <p>� 2024 Food4You Inc. All rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
